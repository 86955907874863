import { Box, Typography } from "@mui/material";
import { summaryBarStyles } from "./dashboardComponentStyles";

const SummaryBar = (props) => {
  const { name, value, totalUsers = 10, colour = "#A7C4A1" } = props;
  const classes = summaryBarStyles();
  return (
    <Box className={classes.outerContainer}>
      <Typography>{name}</Typography>
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: "0.4rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ColouredBarComponent colour={colour} value={value / totalUsers} />
        <Typography sx={{ marginLeft: "0.4rem" }}>{value}</Typography>
      </Box>
    </Box>
  );
};

const ColouredBarComponent = (props) => {
  const { value, colour } = props;
  return (
    <Box
      sx={{
        backgroundColor: colour,
        width: value,
        borderRadius: 1,
        padding: "0.6rem",
        height: "1.2rem",
      }}
    ></Box>
  );
};

export default SummaryBar;
