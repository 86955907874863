import { makeStyles } from "@mui/styles";

export const dashboardTopBarStyles = makeStyles({
  outerContainer: {
    borderRadius: "0.2rem",
    display: "flex",
    width: "100%",
  },
});

export const dashboardTopBarItemStyles = makeStyles({
  outerContainer: {
    display: "flex",
    padding: "0.8rem",
    flexDirection: "column",
    alignItems: "center",
    width: 100,
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#494949",
      textTransform: "none",
    },
    "& .MuiTypography-h3": {
      margin: 0,
      fontSize: "2rem",
    },
  },
  title: {
    textAlign: "center",
  },
});
export const summaryPieStyles = makeStyles({
  outerContainer: {
    backgroundColor: "#A7C4A1",
    padding: "0.6rem",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    borderRadius: 8,
    width: 315,
    height: 80,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "@media (max-width: 1460px)": {
      width: 290,
    },
  },
  boxRight: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .MuiTypography-h2": {
      fontWeight: "800",
      color: "white",
      marginBottom: "0.2rem",
      fontSize: 18,
    },
    "& .MuiTypography-h3": {
      fontSize: 14,
      color: "white",
    },
  },
  boxLeft: {
    backgroundColor: "white",
    height: 80,
    borderRadius: "50%",
    padding: "0.4rem",
  },
});
export const summaryLineStyles = makeStyles({
  outerContainer: {
    marginRight: "2rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "1.2rem",
    paddingBottom: "3rem",
    borderRadius: 8,
    width: "61%",
    // flexGrow: 1,
    height: "350px",
    // display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    "@media (max-width: 1460px)": {
      maxWidth: "100%",
    },
  },
  staticOuterContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "1.2rem",
    paddingBottom: "3rem",
    borderRadius: 8,
    width: "29%",
    // flexGrow: 1,
    height: "350px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    "@media (max-width: 1460px)": {
      maxWidth: "100%",
    },
  },
  title: {
    marginBottom: "0.8rem !important",
  },
});

export const responsesLogStyles = makeStyles({
  outerContainer: {
    marginTop: "1rem",
    backgroundColor: "white",
    padding: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    borderRadius: 8,
    "& .MuiTypography-h3": {
      fontSize: 18,
      fontWeight: "600",
    },
  },
});

export const responsesLogItemStyles = makeStyles({
  outerContainer: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  details: { marginLeft: "1rem", marginRight: "1rem" },
});

export const summaryBarStyles = makeStyles({
  outerContainer: {
    width: 500,
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0.4rem",
  },
  group: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupInner: {
    display: "flex",
  },
});
export const summaryBarGroupStyles = makeStyles({
  outerContainer: {
    backgroundColor: "white",
    padding: "1rem",
    marginTop: "1rem",
    marginRight: "2rem",
    "& .MuiTypography-h3": {
      fontSize: 18,
      fontWeight: "600",
    },
  },
});
