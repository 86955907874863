import { scoreCardStyles } from "./pagesStyles";
import {
  Avatar,
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import RatingsComponent from "../components/RatingsComponent";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  addAnswer,
  getProfile,
  getQuestionDetails,
  getRandomQuestion,
  getSettings,
} from "../apiHelper";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

function ScoreCard() {
  const classes = scoreCardStyles();
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [profileInfo, setProfileInfo] = useState({});
  const [score, setScore] = useState("");
  const [questionData, setQuestionData] = useState({});
  const [value, setValue] = useState("");
  const [anon, setAnon] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((t) => {
        getSettings(t).then((s) => {
          if (s.data.length > 0 && s.data[0].settingQuestionType === "set") {
            getQuestionDetails(t, {
              dayOfWeek: moment().format("dddd").toLowerCase(),
              requestingUserId: user.sub,
              date: new Date(),
            })
              .then((q) => {
                setQuestionData(q);
                getProfile(t, {
                  externalId: user.sub,
                  currentTime: new Date(),
                  questionId: q ? q._id : null,
                }).then((p) => {
                  if (p.profileUser.answeredToday) {
                    history("/success", {
                      state: {
                        alreadyAnswered: true,
                        accountType: p.accountType,
                      },
                    });
                  } else {
                    setProfileInfo(p.profileUser);
                    if (p.profileUser.accountType === "sports") {
                      setAnon(false);
                    }
                    setLoaded(true);
                  }
                });
              })
              .catch((e) => {
                setLoaded(true);
                // history("/not-yet-setup", {});
              });
          } else if (
            s.data.length > 0 &&
            s.data[0].settingQuestionType === "random"
          ) {
            getRandomQuestion({
              requestingUserId: user.sub,
            })
              .then((q) => {
                setQuestionData(q.data);
                getProfile(t, {
                  externalId: user.sub,
                  currentTime: new Date(),
                  questionId: q.data._id,
                }).then((p) => {
                  if (p.profileUser.answeredToday) {
                    console.log("here");
                    history("/success", {
                      state: {
                        alreadyAnswered: true,
                        accountType: p.accountType,
                      },
                    });
                  } else {
                    setProfileInfo(p.profileUser);
                    if (p.profileUser.accountType === "sports") {
                      setAnon(false);
                    }
                    setLoaded(true);
                  }
                });
              })
              .catch((e) => {
                history("/not-yet-setup", {});
              });
          } else {
            history("/not-yet-setup", {});
          }
        });
      });
    }
  }, [user]);

  const determineNavigate = () => {
    getAccessTokenSilently().then((t) => {
      addAnswer(t, {
        questionId: questionData._id,
        value: value,
        score: score,
        answererId: user.sub,
        anonymous: anon,
        createdAt: new Date(),
      }).then(() => {
        if (score < 5 && value === "") {
          history("/help", {
            state: {
              questionId: questionData._id,
              score: score,
            },
          });
        } else {
          //TODO
          // think about where to redirect - possibly setup by company at signup
          history("/success", {
            state: {
              alreadyAnswered: false,
            },
          });
        }
      });
    });
  };

  return (
    <Box className={classes.backdrop}>
      {loaded ? (
        <Box className={classes.outerContainer}>
          <Box className={classes.welcomeMessageContainer}>
            <Avatar></Avatar>
            <Typography variant="h1">
              Good Morning, {profileInfo.firstName}
            </Typography>
          </Box>
          <Box className={classes.pointSelectorWrapper}>
            {questionData ? (
              <>
                <Typography variant="body1">
                  {questionData ? questionData.question : ""}
                </Typography>
                <RatingsComponent
                  questionData={questionData}
                  points={10}
                  onClick={setScore}
                  score={score}
                  setValue={setValue}
                  value={value}
                />
              </>
            ) : (
              <Typography variant="body1">
                There's no question for today! Enjoy your day.
              </Typography>
            )}
          </Box>

          <Box className={classes.buttonContainer}>
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              onClick={() =>
                history("/something-else", {
                  state: {
                    from: "scoreCard",
                  },
                })
              }
            >
              <Typography variant="body1">
                I want to talk about something else
              </Typography>
            </Button>
            <Box className={classes.buttonContainerRight}>
              <FormGroup>
                {profileInfo.accountType !== "sports" ? (
                  <FormControlLabel
                    sx={{
                      color: "#456A44",
                    }}
                    control={
                      <Checkbox
                        sx={{ color: "#456A44" }}
                        checked={anon}
                        onChange={(event) => {
                          setAnon(event.target.checked);
                        }}
                      />
                    }
                    label="Anonymous"
                  />
                ) : null}
              </FormGroup>
              <Button
                sx={{
                  textTransform: "none",
                  marginLeft: "0.4rem",
                  borderRadius: "20px",
                  backgroundColor: "#A7C4A1",
                  height: "2rem",
                }}
                disabled={!(score !== "" || value !== "")}
                color="primary"
                variant="contained"
                onClick={determineNavigate}
              >
                <Typography variant="body1">Submit</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default ScoreCard;
