import { makeStyles } from "@mui/styles";

export const ratingsStyles = makeStyles({
  ratingsContainer: {
    display: "flex",
    alignItems: "center",
  },
  lineSegment: {
    flex: "1 0",
    height: "0.2rem",
    backgroundColor: "#c2e4bb",
  },
  firstLast: {
    opacity: 0,
  },
  checkBoxContainer: {
    flexShrink: 0,
    position: "relative",
  },
  iconContainer: {
    flex: "1 1",
    display: "flex",
    flexDirection: "row",
    minWidth: 0,
    alignItems: "center",
  },
  checkBox: {
    height: "2rem",
    width: "2rem",
    alignItems: "center",
    backgroundColor: "#A7C4A1",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRadius: "50%",
    cursor: "pointer",
  },
  insideBox: {
    height: "1.7rem",
    width: "1.7rem",
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRadius: "50%",
    cursor: "pointer",
  },
  innerInsideBox: {
    height: "1.35rem",
    width: "1.35rem",
    alignItems: "center",
    backgroundColor: "#A7C4A1",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRadius: "50%",
    cursor: "pointer",
  },
});

export const helpContainerComponentStyles = makeStyles({
  helpContainerOuter: {
    margin: "1rem",
    padding: "1rem",
    paddingBottom: "1.5rem",
    "& .MuiTypography-h1": {
      fontSize: "1.2rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "2rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.2rem",
      fontWeight: 300,
      textTransform: "none !important",
    },
    // margin: "1rem",
  },

  helpSubmitContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1rem",
  },

  skipContainerOuter: {
    margin: "1rem",
    padding: "1rem",
    "& .MuiTypography-h1": {
      fontSize: "1.2rem",
    },

    "& .MuiTypography-body1": {
      padding: "1.4rem",
      fontSize: "1.2rem",
      fontWeight: 300,
    },
    margin: "1rem",
    borderTop: "0.1rem solid #456A44",
  },
  skipSubmitRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  helpDateContainer: {
    padding: "2rem",
    // minWidth: "60%"
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1.5rem",
    },
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  arrowLeft: {
    marginRight: "0.8rem",
    cursor: "pointer",
  },
  arrowLeftDisabled: {
    marginRight: "0.8rem",
    color: "#d2d2d2",
  },
  arrowRight: {
    marginLeft: "0.8rem",
    cursor: "pointer",
  },
  arrowRightDisabled: {
    marginLeft: "0.8rem",
    color: "#d2d2d2",
  },
  personSelectorContainer: {
    display: "flex",
    alignItems: "center",
  },
  helpDateInner: {
    display: "flex",
  },
  availableDatesContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1.5rem",
  },
  availableDatesListContainer: {
    height: 350,
    overflow: "auto",
  },
  appointment: {
    width: 110,
    padding: "0.2rem",
    backgroundColor: "#f2f2f2",
    margin: "0.2rem",
    cursor: "pointer",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
      fontWeight: 300,
      textTransform: "none !important",
    },
  },
  appointmentSelected: {
    width: 110,
    padding: "0.2rem",
    backgroundColor: "#9FC59E",
    margin: "0.2rem",
    cursor: "pointer",
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
      fontWeight: 300,
      textTransform: "none !important",
    },
  },
});
